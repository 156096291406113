import { doQuery } from "@/api/core"
import { mapGetters, mapMutations } from 'vuex'
import DaliySign from '@/components/daliy-sign/daliy-sign.vue'
import StudyRankData from '@/components/study-rank-data/study-rank-data.vue'
import ScoreRank from '@/components/score-rank/score-rank.vue'
import VueHoverMask from '@/components/hover-mask'
import dateUtils from '@/utils/dateUtils'
import util from "../../../utils/cmyUtils"

export default {
    name: "index",
    data() {
        return {
            //当前
            currentTime: null,
            timeHandler: null,

            recommend_lessons: [],
            tasks: [],
            task_loading: false,

            daliySignTaskId: undefined,
            studyRankTaskId: undefined,

            //最新课程
            latestLessons: [],
            latestQuestions: [],

            //
            boShowMoreGoingTask: false,

            score_rank_drawer: false
        }
    },
    components: {
        DaliySign,
        StudyRankData,
        VueHoverMask,
        ScoreRank
    },

    mounted() {
        let that=this;

        that.updateCurrentTime();

        //定期更新当前时间
        that.timeHandler = setInterval(function() {
            that.updateCurrentTime();
        }, 60000);

        this.queryRecommendLessons()
        this.queryTasks()
        this.queryLatestLessons()
    },

    beforeDestroy() {

        //清除定时器
        const that = this
        if (that.timeHandler) {
            clearInterval(that.timeHandler);
            that.timeHandler = null;
        }

    },

    computed: {
        ...mapGetters([
            'myInfo',
            'myStaffInfo',
            'isAdmin',
            'trainingId',
            'trainingVisible',
            'seriesId',
            'seriesVisible',
            'mobilephone'
        ])
    },
    methods: {

        updateCurrentTime() {
            let that = this;

            //更新当前时间，用于判断是否可以开始
            that.currentTime = dateUtils.getNow();

            //更新任务的截止时间
            if (that.tasks && that.tasks.length > 0){

                that.tasks.forEach(function(item,index){

                    if (item.deadline_time){
                        item.deadmins = util.getDateDiffMinutes(item.deadline_time);
                        item.deadminsFormat = util.formatMinutesEx(item.deadmins);
                    }

                })
            }

            console.log(that.tasks)
        },

        ...mapMutations([
            'SET_TRAINING_ID',
            'SET_TRAINING_VISIBLE',
            'SET_SERIES_ID',
            'SET_SERIES_VISIBLE'
        ]),

        onMoreGoingTask:function(){
            console.log(this.boShowMoreGoingTask)
            if (!this.boShowMoreGoingTask)
                this.boShowMoreGoingTask = true;
            else
                this.boShowMoreGoingTask = false;
        },

        toLessonDetail(lessonId) {
            const that = this
            that.$router.push({path: '/student/singleLesson?lessonId=' + lessonId})
        },

        queryLatestLessons(){
            const that = this
                doQuery({
                    query_code: 'LN7DX',
                    param_str2: that.mobilephone
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK) {
                        that.latestLessons = res.data
                    }
                })
        },

        queryRecommendLessons() {
            const that = this
            if(that.isAdmin) {
                doQuery({
                    query_code: 'LNL00'
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK) {
                        that.recommend_lessons = res.data
                    }
                })
            }
        },

        queryTasks() {
            const that = this
            const hide = this.$message.loading('正在加载中...', 0)
            doQuery({
                query_code: 'LN100',
                param_str1: '0',
                page: 1,
                limit: 1000
            }).then(res=>{
                if(res.errcode === that.$consts.biz.OK && res.data) {

                    that.tasks = [];
                    res.data.forEach(function(item,index){
                        if (!(item.deadmins <= 0 && item.allow_delay == '0' && item.deadmins < 0 - 7*24*60)){
                            that.tasks.push(item)
                        }
                    })

                    that.tasks.forEach((item, index) => {

                        //格式化剩余时间
                        item.deadminsFormat = util.formatMinutesEx(item.deadmins);
                        that.queryTaskItems(index)
                    })
                }
            }).finally(() => {
                setTimeout(hide, 1000)
            })
        },

        queryTaskItems(index) {
            const that = this
            const { tasks } = that
            that.task_loading = true
            if(tasks[index].tasktype_code == that.$consts.biz.TASK_TYPES.COMP) {
                doQuery({
                    query_code: 'LN110',
                    param_str2: tasks[index].task_id,
                    page: 1,
                    limit: 9
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.$set(that.tasks[index], 'taskitems', res.data);
                    }else {
                        that.$set(that.tasks[index], 'taskitems', []);
                    }
                    that.task_loading = false
                })
            }
            setTimeout(() => {
                if(that.task_loading) {
                    that.task_loading = false
                }
            },1000)
        },

        onSelectRecommend(index) {
            const that = this
            that.$router.push({path: '/student/singleLesson?lessonId=' + that.recommend_lessons[index].lesson_id});
        },

        toDoTask(index) {
            const that = this
            var task = that.tasks[index]

            //如果超时，而且不允许超时完成，则禁止
            if (task.allow_delay == '0' && task.deadline_time < that.currentTime ) {

                that.$message.error('该任务已截止')
                return;

            }

            //如果超时，而且不允许超时完成，则禁止
            if (task.start_time > that.currentTime ) {

                that.$message.error('该任务未开始')
                return;

            }

            //如果是调查问卷
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.INVEST) {
                that.$router.push({path: '/student/questionaire?taskId=' + task.task_id })
            }

            //如果是直接考试
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.EXAM) {
                that.$router.push({path: '/student/examination?taskId=' + task.task_id })
            }

            //如果是单次课程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.LESSON || task.tasktype_code == that.$consts.biz.TASK_TYPES.SELF) {
                that.$router.push({path: '/student/singleLesson?taskId=' + task.task_id})
            }

            //如果是系列课程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.SERIES) {
                if(that.seriesId && that.seriesId == task.task_id) {
                    that.SET_SERIES_VISIBLE(!that.seriesVisible)
                }else {
                    that.SET_TRAINING_ID(null)
                    that.SET_SERIES_ID(task.task_id)
                }
            }
            //如果是训练营程培训
            if (task.tasktype_code == that.$consts.biz.TASK_TYPES.TRAIN) {
                if(that.trainingId && that.trainingId == task.task_id) {
                    that.SET_TRAINING_VISIBLE(!that.trainingVisible)
                }else {
                    that.SET_TRAINING_ID(task.task_id)
                }
            }

        },
        toTaskCenter() {
            const that = this
            that.$router.push({name: 'StudentTask'});
        },
        doDaliySign(taskId) {
            const that = this
            that.daliySignTaskId = taskId
        },
        showStudyRankData(taskId) {
            const that = this
            that.studyRankTaskId = taskId
        }
    }
}
